import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'
import styled, { css, createGlobalStyle } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { getLastTextIndexes } from '../utils'
import { useMeasure, useMount } from 'react-use'
import { useViewportScroll, motion, useTransform } from 'framer-motion'
import { use100vh } from 'react-div-100vh'
import { Link as ScrollLink, Element as ScrollElement } from 'react-scroll'

import { Layout, PageHeader, Hero, Block, ImageOrVideo } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, textDot } from '../styles/global'

import { PageTitleContext } from '../context/PageTitleContext'

const ProjectsSingle = (props) => {
    const data = props?.pageContext?.data
    const prevProject = props?.pageContext?.prevProject
    const nextProject = props?.pageContext?.nextProject
    const screenHeight = use100vh()
    const { pageTitle, setPageTitle } = useContext(PageTitleContext)
    const [heroRef, { bottom }] = useMeasure()
    const { scrollY } = useViewportScroll()
    const opacity = useTransform(scrollY, [0, bottom * 0.75], [1, 0])

    useMount(() => {
        setPageTitle(data?.title)
    })

    const renderSectionNav = (nextSection, offset) => {
        return (
            <SectionNav>
                {nextSection && (
                    <Next
                        as={ScrollLink}
                        to={nextSection}
                        offset={offset ? offset : 0}
                        smooth={true}
                        duration={500}
                    />
                )}
            </SectionNav>
        )
    }

    const renderIntro = () => {
        return (
            <>
                <Intro
                    ref={heroRef}
                    style={{
                        height: screenHeight - 80,
                    }}
                >
                    {renderSectionNav('info-section')}
                    <BgColour style={{ opacity }} />
                    <Container>
                        <Grid columns={2}>
                            <Left />
                            <Right>
                                <Description
                                    dangerouslySetInnerHTML={{
                                        __html: data?.hero_content,
                                    }}
                                />
                            </Right>
                        </Grid>
                    </Container>
                </Intro>
            </>
        )
    }

    const renderCategories = () => {
        if (!data.cats) return

        return data.cats.map((item, i) => {
            return <CatItem key={i}>{item.name}</CatItem>
        })
    }

    const renderInfo = () => {
        if (!data.project_info) return

        const items = data.project_info.map((item, i) => {
            return (
                <InfoItem key={i}>
                    <Label>{item.label}</Label>
                    <Value>{item.value}</Value>
                </InfoItem>
            )
        })

        return (
            <Info as={ScrollElement} name={'info-section'}>
                <Container>
                    <Grid columns={2}>
                        <Left></Left>
                        <Right>
                            <Cats>{renderCategories()}</Cats>
                            <InfoItems>{items}</InfoItems>
                        </Right>
                    </Grid>
                </Container>
            </Info>
        )
    }

    const renderImage = (item, i) => {
        const image = item?.image || item?.image_1
        const image2 = item?.image_2

        return (
            <ImageItem key={i}>
                <Grid columns={item.acf_fc_layout === 'double_image' ? 2 : 1}>
                    {item?.acf_fc_layout == 'single_image' && (
                        <ImageOrVideo
                            media_type={item?.media_type}
                            image={item?.image}
                            video={item?.video}
                            video_embed={item?.video_embed}
                        />
                    )}
                    {item?.acf_fc_layout != 'single_image' && image && (
                        <Image key={`${image.id}-1`} src={image?.sizes?.medium2}>
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                    {item?.acf_fc_layout != 'single_image' && image2 && (
                        <Image
                            key={`${image2.id}-2`}
                            src={image2?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                </Grid>
            </ImageItem>
        )
    }

    const renderText = (item, i) => {
        return (
            <TextItem key={i}>
                <Description
                    key={i}
                    dangerouslySetInnerHTML={{
                        __html: item.text,
                    }}
                />
            </TextItem>
        )
    }

    const renderBlocks = () => {
        if (!data.project_blocks) return

        const items = data.project_blocks.map((item, i) => {
            switch (item.acf_fc_layout) {
                case 'single_image':
                case 'double_image':
                    return renderImage(item, i)
                    break

                case 'text_block':
                    return renderText(item, i)
                    break

                default:
                    return false
                    break
            }
        })

        return (
            <Blocks>
                <Container>{items}</Container>
            </Blocks>
        )
    }

    const renderViewAll = () => {
        return (
            <ViewAllNav>
                <Container>
                    <Grid>
                        <ViewAllLink
                            as={Link}
                            to={'/projects'}
                        >
                            View all projects
                        </ViewAllLink>
                    </Grid>
                </Container>
            </ViewAllNav>
        )
    }

    const renderProjectNav = () => {
        if (!prevProject && !nextProject) return

        return (
            <ProjectNav>
                <Container>
                    <Grid columns={2}>
                        <Left>
                            {prevProject && (
                                <>
                                    <Label
                                        as={Link}
                                        to={`/projects/${prevProject.slug}/`}
                                    >
                                        Previous Project<br/>
                                        {prevProject?.title}
                                    </Label>
                                    <ImageWrapper
                                        as={Link}
                                        to={`/projects/${prevProject.slug}/`}
                                    >
                                        <Hero
                                            media_type={
                                                prevProject.heroData.mediaType
                                            }
                                            image={prevProject.heroData.image}
                                            video={prevProject.heroData.video}
                                        />
                                    </ImageWrapper>
                                </>
                            )}
                        </Left>
                        <Right>
                            {nextProject && (
                                <>
                                    <Label
                                        as={Link}
                                        to={`/projects/${nextProject.slug}/`}
                                    >
                                        Next Project<br/>
                                        {nextProject?.title}
                                    </Label>
                                    <ImageWrapper
                                        as={Link}
                                        to={`/projects/${nextProject.slug}/`}
                                    >
                                        <Hero
                                            media_type={
                                                nextProject.heroData.mediaType
                                            }
                                            image={nextProject.heroData.image}
                                            video={nextProject.heroData.video}
                                        />
                                    </ImageWrapper>
                                </>
                            )}
                        </Right>
                    </Grid>
                </Container>
            </ProjectNav>
        )
    }

    return (
        <Layout 
            meta={data && data.seo}
            isProject={true}
        >
            <Wrapper>
                {renderIntro()}
                <HeroSizer
                    style={{
                        height: screenHeight - 80,
                    }}
                >
                    <Hero
                        isProjectHero={true}
                        media_type={data?.hero_media_type}
                        image={data?.hero_image}
                        video={data?.hero_video}
                    />
                </HeroSizer>
                <Content>
                    {renderInfo()}
                    {renderBlocks()}
                    {renderViewAll()}
                    {renderProjectNav()}
                </Content>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const ExternalLink = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    ${tw`relative w-full`};
    overflow: hidden;
`

const Container = styled.div`
    ${container};
    ${padding};
`

// Grid system

const Left = styled.div``
const Right = styled.div``

const Grid = styled.div`
    ${tw`flex w-full`};

    ${media.phone`
        ${tw`flex-col`};
    `}

    > * {
        ${tw`w-full`};
    }

    ${(props) => {
        if (props.columns === 2)
            return css`
                > *:first-child,
                > *:nth-child(2) {
                    ${tw`w-1/2`};
                    
                    ${media.phone`
                        ${tw`w-full`};
                    `}
                }

                > *:first-child {
                    padding-right: 1.5rem;

                    ${media.phone`
                        padding-right: 0;
                    `}
                }

                > *:nth-child(2) {
                    padding-left: 1.5rem;

                    ${media.phone`
                        padding-left: 0;
                    `}
                }
            `
    }}

    ${media.phone`
        > *:first-child,
        > *:nth-child(2) {
            margin-bottom: 3rem;
        }
    `}
`

// Intro

const BgColour = styled(motion.div)`
    ${tw`absolute inset-0`};
    background: rgba(224, 224, 224, 0.9);
`

const Intro = styled(motion.div)`
    ${tw`relative`};
    z-index: 1;

    ${Container} {
        ${tw`relative flex h-full items-center`};
    }

    ${Description} {
        max-width: 30rem;
    }
`

// Hero

const HeroSizer = styled.div`
    ${tw`absolute top-0 inset-x-0`};

    .hero-overlay {
        opacity: 0;
    }
`

// Section Nav

const Next = styled.div``
const Prev = styled.div``
const SectionNav = styled.div`
    z-index: 3;
    ${tw`absolute inset-0 w-full h-full`};
    pointer-events: none;

    ${Next} {
        ${tw`absolute inset-0 w-full h-full`};
        pointer-events: initial;
        cursor: url(${require('../assets/images/icons/arrow-down-grey.svg')}),
            auto;
    }
`

// Content

const Content = styled.div`
    ${tw`relative`};
    z-index: 2;
    background: rgb(224, 224, 224);
`

// Project Info

const Cats = styled.div``
const CatItem = styled.div``
const Label = styled.div``
const Value = styled.div``
const InfoItems = styled.div``
const InfoItem = styled.div`
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-top: 1px solid #8c8c8c;

    &:last-child {
        border-bottom: 1px solid #8c8c8c;
    }

    ${Label} {
        padding-right: 32px;
    }

    ${Label}, ${Value} {
        width: 50%;
        line-height: 1;
    }
`

const Info = styled.div`
    ${tw`relative w-full`};
    z-index: 1;

    ${Container} {
        ${tw`relative flex items-center border-t border-darkGrey`};
    }

    ${Grid} {
        border-bottom: 1px solid #8c8c8c;
        ${Left}, ${Right} {
            padding-top: 1.875em;
            padding-bottom: 3rem;
        }
    }

    ${Cats} {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 3rem;

        ${CatItem}:not(:last-child) {
            margin-right: 1rem;
        }

        ${CatItem} {
            ${textDot}
        }
    }
`

// Blocks

const Blocks = styled.div`
    ${tw`relative flex flex-wrap`};
    padding-top: 3rem;
    z-index: 1;
    display: block;
`

const ImageItem = styled.div`
    ${tw`w-full`};

    &:not(:last-child) {
        margin-bottom: 3rem;

        ${media.phone`
            margin-bottom: 0;
        `}
    }
`

const TextItem = styled.div`
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-top: 1px solid #8c8c8c;
    border-bottom: 1px solid #8c8c8c;

    ${Description} {
        ${tw`w-8/12 mx-auto`};
        &,
        * {
            font-size: 2rem;
            line-height: 1.1;
            letter-spacing: -0.02em;
        }
    }

    &:not(:last-child) {
        margin-bottom: 3rem;
    }
`

// View all nav

const ViewAllLink = styled.div``
const ViewAllNav = styled.div`
    margin-top: 3rem;

    ${Grid} {
        ${tw`flex flex-col items-center text-center`};
        border-top: 1px solid #8c8c8c;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    ${ViewAllLink} {
        width: initial;
        font-size: 2rem;
        line-height: 1.1;
        letter-spacing: -0.02em;
    }
`

// Project Nav

const ImageWrapper = styled.div``

const ProjectNav = styled.div`
    ${tw`relative w-full`};
    z-index: 1;

    ${Grid} {
        ${tw`border-t border-b border-darkGrey`};
    }

    ${Left}, ${Right} {
        ${tw`flex flex-col items-start w-1/2`};
        padding-top: 3rem;
        padding-bottom: 3rem;

        ${media.phone`
            ${tw`w-full`};
        `}
    }

    ${Left} {
        ${media.phone`
            padding-bottom: 0;
        `}
    }

    ${Right} {
        ${tw`ml-auto`};
    }

    ${Label} {
        /* line-height: 1; */
        margin-bottom: 1rem;
    }

    ${ImageWrapper} {
        ${tw`relative w-full`};
        height: 0;
        padding-top: 69.06%;
    }

    .hero-overlay {
        opacity: 0;
    }
`

export default ProjectsSingle
